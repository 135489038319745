import React, { ReactNode } from 'react';
import {
  AppShell as MantineAppShell,
  AppShellProps,
  Button,
  Loader,
  Text,
  AppShellMain,
} from '@mantine/core';
import Navbar from './Navbar';
import { useSession } from '../../hooks/useSession';
import { UserRole } from '../../graphql/types.generated';
import Link from 'next/link';

export type MainAppShellProps = {
  children: ReactNode;
  withAuth?: boolean;
  adminOnly?: boolean;
  loading?: boolean;
  navbar?: ReactNode;
  header?: AppShellProps['header'];
  aside?: AppShellProps['aside'];
  fixed?: boolean;
};

const MainAppShell = ({
  children,
  withAuth,
  adminOnly,
  navbar,
  fixed = false,
}: MainAppShellProps) => {
  const { session, loading: loadingSession } = useSession();
  const showLoader = (withAuth || adminOnly) && (typeof window === 'undefined' || loadingSession);
  let childrenToShow = null;

  if (withAuth && !loadingSession && !session) {
    childrenToShow = <div>You are not authenticated</div>;
  }

  if (adminOnly && session?.user?.role !== UserRole.Admin) {
    childrenToShow = (
      <div>
        <Text>You are not authenticated 😿</Text>
        <Link legacyBehavior href="/login" passHref>
          <Button mt="md">Login</Button>
        </Link>
      </div>
    );
  }

  if (showLoader) {
    childrenToShow = <Loader />;
  }

  return (
    <MantineAppShell
      bg="gray.1"
      mih="100vh"
      pb="xl"
      header={{ height: 60, offset: false }}
      {...(navbar
        ? {
            navbar: {
              width: 200,
              breakpoint: 'md',
            },
          }
        : {})}
      padding="md"
    >
      {navbar}
      <Navbar />
      <AppShellMain>{childrenToShow || children}</AppShellMain>
    </MantineAppShell>
  );
};

export default MainAppShell;
