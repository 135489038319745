import { Anchor, Box, Button, Group, AppShellHeader, Menu, Select, Tooltip } from '@mantine/core';
import Link from 'next/link';
import { useHotkeys } from '@mantine/hooks';
import { useRouter } from 'next/router';
import React, { memo, useCallback, useRef } from 'react';
import { useLogout, useSession } from '../../hooks/useSession';
import justinHead from '../../../public/images/justin-head.png';
import Image from 'next/image';
import FullLogo from '../Logos/FullLogo';
import { usePolicyholdersNamesQuery } from '../../graphql/operations/policyholders.generated';
import { Search } from '@emotion-icons/ionicons-outline/Search';
import { IS_PREVIEW, IS_PRODUCTION, IS_AUDIT } from '../../constants';

const getNavColor = () => {
  if (IS_PREVIEW) {
    return 'green.7';
  }
  if (IS_PRODUCTION) {
    return 'white';
  }

  return 'cyan.7';
};

const Navbar = ({ forInsured = false }: { forInsured?: boolean }) => {
  const searchRef = useRef<HTMLInputElement>(null);

  const router = useRouter();
  const { session } = useSession();
  const logout = useLogout();
  const { data } = usePolicyholdersNamesQuery({
    skip: !session?.user,
  });
  const orgLogoUrl = session?.organization?.logoUrl;

  const onSearchChange = useCallback(
    (value) => {
      router.push(`/u/policyholders/${value}`);
    },
    [router]
  );

  useHotkeys([['mod+K', () => searchRef.current?.focus()]]);

  return (
    <AppShellHeader h={60} bg={getNavColor()} px="md" zIndex={100} display="flex" pos="relative">
      <div style={{ flex: '1' }}>
        <Link legacyBehavior href={'/u'} passHref>
          <a
            className="logo"
            style={{
              float: 'left',
              display: 'flex',
              justifyContent: 'center',
              height: '100%',
              flexDirection: 'column',
              paddingRight: 12,
            }}
          >
            {!IS_AUDIT &&
            (process.env.NEXT_PUBLIC_VERCEL_ENV || process.env.VERCEL_ENV) === 'preview' ? (
              <>
                <Tooltip label="Hype Hype Hype" position="right">
                  <Box
                    className="d-flex align-items-center"
                    // style={{
                    //   '&:hover': {
                    //     transform: 'scaleX(-1)',
                    //   },
                    // }}
                  >
                    <Image alt="alt" src={justinHead} height={30} width={24} />
                    <Image alt="alt" src={justinHead} height={30} width={24} />
                    <Image alt="alt" src={justinHead} height={30} width={24} />
                  </Box>
                </Tooltip>
              </>
            ) : (
              <>
                <FullLogo />
              </>
            )}
            {orgLogoUrl ? (
              <div
                style={{
                  height: 64,
                  padding: '16px 0px',
                }}
                className="d-flex align-items-center"
              >
                {/* eslint-disable-next-line @next/next/no-img-element */}
                <img src={orgLogoUrl} height="100%" alt="Organization logo" />
              </div>
            ) : null}
          </a>
        </Link>
      </div>

      {forInsured ? (
        <>
          <div style={{ flex: '1', justifyContent: 'end', alignItems: 'center', display: 'flex' }}>
            <Button component="a" href="mailto:support@withshepherd.com">
              Need help?
            </Button>
          </div>
        </>
      ) : (
        <div style={{ flex: '1', justifyContent: 'end', alignItems: 'center', display: 'flex' }}>
          {session?.user?.id && (
            <Group gap={16}>
              {/* <ActionIcon
                style={{ visibility: IS_PRODUCTION ? 'hidden' : 'visible' }}
                onClick={() => toggleShepsyAI()}
                size="md"
              >
                <ChatboxEllipses size={18} color="black" />
              </ActionIcon> */}
              <Select
                ref={searchRef}
                searchable
                onChange={onSearchChange}
                placeholder="Search"
                size="xs"
                maxDropdownHeight={280}
                leftSection={<Search size={14} />}
                rightSection={<span />}
                data={
                  data?.policyholdersNames?.map((policyholder) => ({
                    label: `${policyholder.name}${policyholder.deletedAt ? ' (archived)' : ''}`,
                    value: policyholder.id,
                  })) || []
                }
              />

              <>
                <Menu trigger="hover">
                  <Menu.Target>
                    {
                      <Anchor>
                        {`${session?.user?.name || 'User'} ${
                          session?.organization?.name ? `(${session?.organization?.name})` : ''
                        }`}{' '}
                      </Anchor>
                    }
                  </Menu.Target>
                  <Menu.Dropdown>
                    <Menu.Item key="companySettings" onClick={() => router.push('/settings')}>
                      Settings
                    </Menu.Item>

                    <Menu.Item key="logout" onClick={() => logout()}>
                      Logout
                    </Menu.Item>
                  </Menu.Dropdown>
                </Menu>
              </>
            </Group>
          )}
        </div>
      )}
    </AppShellHeader>
  );
};

export default memo(Navbar);
